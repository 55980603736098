import React, { useState, useEffect } from "react";
import {
  Box,
  Text,
  TextInput,
  Group,
  Button,
  RadioGroup,
  Radio,
  Modal,
  validateJson,
  Select,
  UnstyledButton,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { DatePicker } from "@mantine/dates";
import { GetPhoneOTP } from "../../services/auth";
import { ChevronRight } from "react-feather";
import AddressForm from "../address";
import { CountryCodes } from "../../pages/login";
import dayjs from "dayjs";

export interface Address {
  pincode: number | undefined;
  district: string;
  state: string;
  line1: string;
  line2: string;
  landmark?: string;
  city: string;
}

export interface OnboardingFormValues {
  firstName: string;
  lastName: string;
  gender: number;
  dateOfBirth?: string | Date;
  phone: number | undefined;
  otp?: number | undefined;
  countryCode?: number;
  email?: string;
}

interface OnboardingFormProps {
  intialValues: OnboardingFormValues;
  showOTP?: boolean;
  showResend?: boolean;
  loading: boolean;
  error?: string;
  onSubmit(values: OnboardingFormValues, address: Address | undefined): void;
  isProfileForm?: boolean;
  address?: undefined | Address;
  handleChangeNumber(): void;
  errorCode?: any;
  setErrorCode?: any;
  isEdit?: boolean;
}

const OnboardingForm = (props: OnboardingFormProps) => {
  let namePattern = /^[A-Za-z0-9'-. _]+$/;
  let phoneNoPattern = /^\d{10}$/;
  let emailPattern =
    /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(\.[a-zA-Z]{2,})?$/;
  const form = useForm<OnboardingFormValues>({
    initialValues: props.intialValues,
    validate: {
      firstName: (value) =>
        namePattern.test(value) ? null : "Enter correct first name",
      lastName: (value) =>
        namePattern.test(value) ? null : "Enter correct last name",
      phone: (value) =>
        phoneNoPattern.test(String(value))
          ? null
          : "Enter correct phone number",
      // email: (value) =>
      //   /^\S+@\S+$/.test(value as string) ? null : "Invalid email",
      gender: (value) =>
        value === undefined
          ? null
          : value === 0
          ? "Please select the gender"
          : null,
      email: (value) => {
        if (value === undefined || value === "") return null;
        return emailPattern.test(String(value)) ? null : "Invalid email";
      },
    },
  });

  const [address, setAddress] = useState<Address | undefined>(props.address);

  const {
    loading,
    showOTP,
    error,
    errorCode,
    setErrorCode,
    handleChangeNumber,
  } = props;
  const [addressModal, setAddressModal] = useState(false);
  const [time, setTime] = useState<number>(60);
  const [inter, setInter] = useState<number>();
  const [maxDateAllowed, setMaxDateAllowed] = useState<Date>(
    dayjs().subtract(18, "years").toDate()
  );
  useEffect(() => {
    if (time == 0) {
      window.clearInterval(inter);
    }
  }, [time]);

  var buttonElement = document.querySelector(
    ".mantine-DatePicker-calendarHeaderLevel"
  );

  if (buttonElement) {
    buttonElement.id = "onboarding_dob";
  }

  useEffect(() => {
    console.log("error code is:", errorCode);
    if (errorCode === "exists") {
      form.values.otp = undefined;
      setErrorCode("");
    }
  }, [errorCode]);

  const handleSubmit = (values: OnboardingFormValues) => {
    props.onSubmit(values, address);
    setTime(60);
    clearInterval(inter);
    const intervalID = setInterval(() => {
      setTime((time) => {
        return time - 1;
      });
    }, 1000);
    setInter(intervalID);
  };

  const changePhoneNoHandler = () => {
    handleChangeNumber();
    form.values.otp = undefined;
    setTime(60);
    window.clearInterval(inter);
  };
  const getDisableStatus = (date: Date) => {
    if (dayjs(date).isAfter(maxDateAllowed)) {
      return true;
    }
    return false;
  };

  return (
    <Box mx="auto">
      <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
        <TextInput
          id="first_name"
          required
          size="md"
          radius={"md"}
          label="First name"
          disabled={loading || showOTP}
          placeholder="Enter your first name"
          {...form.getInputProps("firstName")}
          py={8}
        />
        <TextInput
          id="last_name"
          required
          size="md"
          radius={"md"}
          label="Last name"
          disabled={loading || showOTP}
          placeholder="Enter your last name"
          {...form.getInputProps("lastName")}
          py={8}
        />

        <RadioGroup
          py={8}
          label="Gender"
          size="md"
          required
          value={form.getInputProps("gender").value.toString()}
          error={form.getInputProps("gender").error}
          onChange={form.getInputProps("gender").onChange}
        >
          <Radio
            id="male_button"
            value={"2"}
            label="Male"
            defaultChecked
            disabled={loading || showOTP || props.isProfileForm}
          ></Radio>
          <Radio
            id="female_button"
            value={"1"}
            label="Female"
            disabled={loading || showOTP || props.isProfileForm}
          ></Radio>
        </RadioGroup>

        <DatePicker
          id="date_birth"
          placeholder="Enter your date of birth"
          dayClassName={() => {
            return "family-mantine-dob-day";
          }}
          // initialLevel="year"
          initialMonth={maxDateAllowed}
          // maxDate={maxDateAllowed}

          renderDay={(date) => {
            const day = date.getDate();
            const year = date.getFullYear();
            const month = date.getMonth();
            const weekDay = date.getDay();
            return (
              <button
                className={`family-mantine-day-renderer-class ${
                  weekDay === 6 || weekDay === 0
                    ? "family-mantine-day-highlight"
                    : ""
                }`}
                disabled={Boolean(getDisableStatus(date))}
              >
                <div>{day}</div>
              </button>
            );
          }}
          // defaultValue={maxDateAllowed}
          label="Date of birth"
          size="md"
          required
          disabled={loading || showOTP}
          // defaultValue={form.getInputProps("dateOfBirth").value}
          radius={"md"}
          {...form.getInputProps("dateOfBirth")}
          py={8}
        />

        {props.isProfileForm && (
          <TextInput
            type="email"
            placeholder="Enter your email"
            required
            label="Email"
            onCopy={(e) => e.preventDefault()}
            onPaste={(e) => e.preventDefault()}
            onCut={(e) => e.preventDefault()}
            size="md"
            radius={"md"}
            py={8}
            disabled={loading}
            {...form.getInputProps("email")}
          />
        )}

        <Group grow={true} style={{ width: "100%", marginBottom: "30px" }}>
          <Select
            id="country_code"
            size="md"
            radius="md"
            label="Country code"
            defaultValue={"91"}
            placeholder="Country code"
            data={CountryCodes}
            style={{ marginBottom: "auto" }}
            // value={form.values.countryCode?.toString()}
            // onChange={(v) => setCountryCode(parseInt(v as string))}
            {...form.getInputProps("countryCode")}
            sx={(theme) => ({
              width: "30%",
              flexGrow: 0,
              [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
                width: "100%",
                flexGrow: 1,
              },
            })}
            disabled={loading || showOTP || props.isProfileForm}
          />
          <TextInput
            id="phone_no_registration"
            required
            size="md"
            radius={"md"}
            disabled={loading || props.isProfileForm || showOTP}
            label="Phone number"
            onCopy={(e) => e.preventDefault()}
            onPaste={(e) => e.preventDefault()}
            onCut={(e) => e.preventDefault()}
            placeholder="Enter your phone number"
            maxLength={10}
            style={{ maxWidth: "100%" }}
            {...form.getInputProps("phone")}
          />
        </Group>

        {showOTP && (
          <>
            <UnstyledButton
              mb="md"
              onClick={() => {
                changePhoneNoHandler();
              }}
            >
              Change phone number?
            </UnstyledButton>
            <TextInput
              required
              size="md"
              radius={"md"}
              disabled={loading}
              label="OTP"
              id="otp_onboarding"
              py={8}
              maxLength={5}
              placeholder="Enter the OTP recieved."
              {...form.getInputProps("otp")}
            />
            <UnstyledButton
              disabled={time > 0}
              style={{
                opacity: time !== 0 ? 0.5 : 1,
              }}
              onClick={() => {
                form.values.otp = undefined;
                handleSubmit(form.values);
              }}
            >
              Resend OTP ({time})
            </UnstyledButton>
          </>
        )}
        {error !== "" && (
          <Text color="red" my={8}>
            {error}
          </Text>
        )}
        {/* { // Not needed, v3/register call does not support addition of addresses
          !props.isProfileForm && 
          <Group position="apart" mt="md" mb="xl" align={"center"}>
            <Text weight={"bold"}>Address</Text>
            <Text
              id="edit_address"
              color={"zyla"}
              weight="bold"
              style={{ cursor: "pointer" }}
              onClick={() => setAddressModal(true)}
            >
              Edit address
            </Text>
          </Group>
        } */}

        {!props.isProfileForm && address !== undefined && (
          <Box
            py={16}
            px={20}
            my={4}
            mb={24}
            sx={(theme) => ({
              border: `1px solid ${theme.colors.gray[4]}`,
              borderRadius: theme.radius.md,
              background: theme.colors.gray[0],
            })}
          >
            <Text weight={"bold"}>
              {`${address.line1} ${address.line2} ${address.city} ${address.district} ${address.landmark} ${address.pincode}`}
            </Text>
          </Box>
        )}

        <Group>
          <Button fullWidth={true} size="lg" type="submit" id="continue">
            {props.isProfileForm ? "Update profile" : "Continue"}
            <ChevronRight />
          </Button>
        </Group>
      </form>
      <Modal
        title="Add address"
        onClose={() => setAddressModal(false)}
        opened={addressModal}
      >
        <AddressForm
          initialValues={address}
          loading={loading}
          onSubmit={(values) => {
            setAddress(values);
            setAddressModal(false);
          }}
        />
      </Modal>
    </Box>
  );
};

export default OnboardingForm;
