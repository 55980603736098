
import {
  Box,
  Text,
  Title,
  Grid,
  Image,
  Button,
  Group,
  Modal,
  Card,
  Popover
} from "@mantine/core";
import { Calendar, ChevronRight, Clock, User, X } from "react-feather";
import empty from "../../assets/events-empty.svg";
import { getDocumentToken } from "../../services/document";
import ApiConfig from "../../utils/apiconfig";
import { api } from "../../utils/api";
import { useState } from "react";
import "./report.css";

type AppointmentCardProps = {
  index: number
  serviceTitle: string;
  date: string;
  timeSlot: string;
  name: string;
  past?: boolean;
  status?: number;
  paymentOrderId?: string;
  onCancel?: (enabled: boolean) => void;
  onReschedule?: () => void;
  link?: number;
  isLabTest?: boolean;
  orderId: string;
};

const statusMapping = {
  "0": "Scheduled",
  "1": "In Progress",
  "2": "Completed",
  "3": "Cancelled",
  "4": "Confirmed",
  "5": "Scheduled",
  "6": "ReSchedule Requested",
  "7": "Phlebo Assigned",
  "9": "Pickup",
  "10": "Sample Collected",
  "11": "Sample Received",
  "12": "Report Generated",
};

const AppointmentCard = ({
  index,
  serviceTitle,
  date,
  timeSlot,
  name,
  past = false,
  status,
  onCancel,
  onReschedule,
  link,
  isLabTest,
  orderId,
}: AppointmentCardProps) => {
  const token = getDocumentToken();
  const [opened, setOpened] = useState<boolean>(false);
  const [url, setUrl]= useState([]);
  const [loading, setLoading]= useState(false);

  const getUrl = async (id: number) => {
    setLoading(true);
    try {
      const { data } = await api.get(ApiConfig.appointment.getDocuments + id);
      if (data.documents && data.documents.length > 0) {
        const documentUrls = data.documents.map((e) => ({ title: e.title, url: e.url }));
        setUrl(documentUrls);
        setOpened(true);
      } else {
        setUrl([{ title: "No report found", url: "" }]);
        setOpened(true);
      }
    } catch (error) {
      console.log("No document found");
    } finally {
      setLoading(false);
    }
  };
  return (
    <Box 
      id={`upcoming_appointment_card_${index}`}
      p={24}
      sx={(theme) => ({
        border: `1px solid ${theme.colors.gray[3]}`,
        borderRadius: theme.radius.md,
        boxShadow: theme.shadows.xs,
        height: "100%",
      })}
    >
      <Group position="apart">
        <Text size="lg" weight={"bold"}>
          {serviceTitle}
        </Text>
        <Text color={"teal"} weight="bold" size="xs">
          {/* @ts-ignore*/}
          {statusMapping[status]}
        </Text>
      </Group>
      <Group pt={"xs"} pb={"md"}>
        <Group spacing={"xs"}>
          <Calendar size={18} />
          <Text size="sm">{date}</Text>
        </Group>
        <Group spacing={"xs"}>
          <Clock size={18} />
          <Text size="sm">{timeSlot}</Text>
        </Group>
        <Group spacing={"xs"}>
          <User size={18} />
          <Text size="sm">{name}</Text>
        </Group>
      </Group>
      {/* {status == 2 && link != "" && ( */}

      {status == 2 && link && (<Popover
              opened={opened}
              onClose={() => setOpened(false)}
              target={<Button
                size="xs"
                radius={"md"}
                onClick={() => {
                  getUrl(link);
                }}
                disabled={loading} // Disable the button while loading
              >
                {loading ? "Loading..." : "View documents"} {/* Display "Loading..." while loading */}
              </Button>}
              width={200}
              position="bottom"
              spacing={"sm"}
            >
        {/* <Button
          size="xs"
          radius={"md"}
          onClick={() => {
            // getDocuments(link);
            console.log("dddd", link);
            getUrl(link);
          }}
        >
          Download report
        </Button> */}
        <div className="list-container">
            {url.map((e, index) => (
              <div key={index} className="list-item">
                <button
                  title={e}
                  onClick={() => window.open(e.url, "_blank")}
                  disabled={e.url === ""}
                >
                  {e.title}
                </button>
              </div>
            ))}
          </div>
        </Popover>
      )}

      {!past && status !== 3 && (
        <Group>
          <Button
            id={`reschedule_appointment_${index}`} 
            variant="light"
            color={"indigo"}
            size="xs"
            onClick={() => onReschedule?.()}
            radius={"md"}
          >
            <Clock size={14} style={{ marginRight: 4 }} />
            Reschedule
          </Button>
          <Button
            variant="light"
            id={`cancel_appointment_${index}`} 
            onClick={() => onCancel?.(orderId === "")}
            color={"red"}
            size="xs"
            radius={"md"}
          >
            <X size={14} style={{ marginRight: 4 }} />
            Cancel
          </Button>
        </Group>
      )}
    </Box>
  );
};

export const EmptyAppointment = () => (
  <Box
    sx={{
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
      width: "100%",
      padding: "20px 0px",
    }}
  >
    <img src={empty} alt="Get started by booking a service" />
    <Text my={16} color={"dark"}>
      Get started by booking a service
    </Text>
  </Box>
);

export default AppointmentCard;
