import {
  Grid,
  Title,
  TextInput,
  Button,
  Text,
  Box,
  Select,
  Group,
  UnstyledButton,
  Loader,
  Image,
  Center,
} from "@mantine/core";
import Benefit from "../components/login/benefit";
import type { BenefitProps } from "../components/login/benefit";
import { Link, useNavigate } from "@tanstack/react-location";
import LoginLayout from "../layouts/LoginLayout";
import {
  GetPhoneOTP,
  GetRedirectUrl,
  VerifyPhoneOTP,
  verifyPhoneOTPGetProfiles,
  autoLoginFlow,
  checkSSOUser,
  LogoutUser,
  GetTenantId,
} from "../services/auth";
import { useEffect, useState } from "react";
import { AxiosError } from "axios";
import { setItem } from "../services/storage";
import { ChevronRight } from "react-feather";
import { ArrowLeft } from "react-feather";
import ProfileIcon from "./../assets/account_circle.svg";
import { jwtDecode } from "jwt-decode";
import { Snackbar } from "@mui/base";
import { generateDocumentTokenCustom } from "../services/document";

// import SSOSignup from "../components/ssosignup";

export const CountryCodes = [
  { value: "91", label: "+91" },
  { value: "971", label: "+971" },
  { value: "1", label: "+1" },
  { value: "44", label: "+44" },
  { value: "61", label: "+61" },
  // { value: "63", label: "+63" },
];
/*
const CountryCodes = [
  { value: "91", label: "India +91" },
  { value: "971", label: "UAE +971" },
  { value: "1", label: "USA +1" },
  { value: "44", label: "UK +44" },
  { value: "1", label: "Canada +1" },
  { value: "61", label: "Australia +61" },
];
*/

const Benefits: BenefitProps[] = [
  {
    title: "Health Risk Assessment",
    subtitle:
      "An assessment to evaluate lifestyle factors, health risks & health goals by a nutritionist that covers all dimensions of a person's total well-being.",
  },
  {
    title: "Advance health checkup",
    subtitle:
      "A full body preventive health check-up that helps us look at any diseases/deficiencies that need medical attention.",
  },
  {
    title: "Doctor consultations",
    subtitle:
      "Senior doctors across reputed institutes like Sir Gangaram, Fortis, Apollo, NHI & Columbia Asia.",
  },
];

const Login = () => {
  const [showOTP, setShowOTP] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [OTP, setOTP] = useState<number>();
  const [OTPData, setOTPData] = useState<any>();
  const [phone, setPhone] = useState<number>();
  const [countryCode, setCountryCode] = useState<number>(91);
  const [phoneError, setPhoneError] = useState<string>();
  const [OTPError, setOTPError] = useState<string>();
  const [time, setTime] = useState<number>(10);
  const [inter, setInter] = useState<number>();
  const [hashLoad, setHashLoad] = useState<boolean>(true);
  const [openSignUp, setOpenSignUp] = useState<boolean>(false);
  const [signupData, setSignUpData] = useState<any>({});
  const [multipleProfileList, setMultipleProfileList] = useState<any>([]);
  const [currentSelectedProfile, setCurrentSelectedProfile] =
    useState<any>(null);
  const [showProfilePicker, setShowProfilePicker] = useState<boolean>(false);
  const [tenantId, setTenantId] = useState<number>(-1);
  const [errorMsg, setErrorMsg] = useState<String>("This profile is disabled");

  const [showSnackbar, setShowSnackbar] = useState(false);
  const [currentDocToken, setCurrentDocToken] = useState({});
  const [termsAgree, setTermsAgree] = useState<boolean>(false);

  const handleShowSnackbar = () => {
    setShowSnackbar(true);
    setTimeout(() => {
      setShowSnackbar(false);
    }, 5000); // Hide after 5 seconds
  };
  const navigate = useNavigate();

  const handleErrorTimer = () => {
    setTimeout(() => {
      setPhoneError("");
      setOTPError("");
    }, 3000);
  };

  const handleUserExistCheck = async (data: any, token: string) => {
    try {
      const response = await checkSSOUser(data, token);

      if (response.data && response.data && response.data.redirect) {
        let searchParams = new URLSearchParams(
          new URL(response.data.redirectUrl).search
        );

        let phoneNo: any = searchParams.get("phoneNo");

        let idToken: any = searchParams.get("idToken");

        autoLoginFlow(phoneNo, idToken)
          .then((res) => {
            setItem("ACCESS_TOKEN", res.data?.token);
            setItem("USER_PROFILE", res.data?.user);
            navigate({ to: "/" });
          })
          .catch((err) => {
            console.log("error while implementing autologin");
            // navigate({ to: "/login" });
          });
      } else {
        // navigate({ to: "/login" });
        // setOpenSignUp(true);
        setHashLoad(false);
      }
    } catch (err) {
      console.log("error", err);
    }
  };

  useEffect(() => {
    let url = window.location.search;
    let searchParams = new URLSearchParams(url);

    let token: any = searchParams.get("access_token");

    if (token) {
      let idToken: any = searchParams.get("id_token");
      let decoded: any = jwtDecode(idToken);

      handleUserExistCheck(
        {
          email: decoded.email,
        },
        token
      );
    } else {
      setHashLoad(false);
    }
  }, []);

  useEffect(() => {
    if (time === 0) {
      window.clearInterval(inter);
    }
  }, [time]);

  useEffect(() => {
    if (tenantId != -1) {
      handleOTP();
    }
  }, [tenantId]);

  const handleGetTenantId = async () => {
    setLoading(true);
    try {
      let res = await GetTenantId({
        countrycode: countryCode as number,
        phone: phone,
      });
      var data: any = res.data;
      setTenantId(data["tenantid"]);
    } catch (error) {
      setLoading(false);
      if (error instanceof AxiosError) {
        setPhoneError(error?.response?.data?.message);
      }
    }
  };

  const handleOTP = async () => {
    if (tenantId == -1) {
      handleGetTenantId();
      return;
    }
    setTime(60);
    try {
      let res = await GetPhoneOTP({
        countrycode: countryCode as number,
        phone: phone,
        tenantId,
      });
      setShowOTP(true);
      setOTPData(res.data);
      setLoading(false);

      setInter(
        setInterval(() => {
          setTime((time) => {
            return time - 1;
          });
        }, 1000)
      );
    } catch (error) {
      if (error instanceof AxiosError) {
        setLoading(false);
        setPhoneError(error?.response?.data?.message);
      }
    }
  };

  const handleLoginChores = (verRes: any) => {
    setItem("ACCESS_TOKEN", verRes?.token);
    setItem("USER_PROFILE", verRes?.user);
    const pp = verRes?.user?.patientProfile;
    navigate({ to: "/" });
  };

  const handleLogin = async () => {
    setLoading(true);
    // generate otp
    const re = /^\d{10}$/;
    if (!re.test(String(phone))) {
      setPhoneError("Please enter correct phone number.");
      handleErrorTimer();
      setLoading(false);
      return;
    }
    if (OTP === undefined) {
      await handleOTP();
      return;
    } else {
      // verify otp

      const re = /^\d{5}$/;
      if (!re.test(String(OTP))) {
        setOTPError("Incorrect OTP");
        handleErrorTimer();
        setLoading(false);
        return;
      }
      try {
        let otpReq = { ...OTPData.user, otp: OTP?.toString() };
        let verRes1: any = await verifyPhoneOTPGetProfiles({ user: otpReq });

        if (verRes1.data.length > 1) {
          let token = await generateDocumentTokenCustom({
            auth_token: verRes1.data[0]?.token?.accessToken,
            phone_no: verRes1.data[0]?.user?.phoneNo,
            client: "patient_android",
          });
          setShowProfilePicker(true);
          setMultipleProfileList(verRes1.data);
          setCurrentSelectedProfile(null);
          setCurrentDocToken(token);

          return;
        }

        let verRes = verRes1.data[0];

        // user logged in here
        if (verRes?.user?.patientProfile === null) {
          // unavailable user
          setOTPError("Phone number not registered. Please sign up.");
        } else {
          handleLoginChores(verRes);
        }
      } catch (error) {
        if (error instanceof AxiosError) {
          if (error?.response?.status === 412) {
            setOTPError("Incorrect OTP");
          } else {
            setOTPError(error?.response?.data?.message);
          }
        }
      }
    }
    setLoading(false);
  };

  const renderProfiles = (ele: any) => {
    return (
      <>
        <div
          onClick={() => {
            if (ele?.user?.patientProfile?.status == 3) {
              handleShowSnackbar();
            } else {
              setCurrentSelectedProfile(ele);
            }
          }}
          style={{
            borderRadius: "8px",
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            justifyContent: "center",
            maxHeight: "330px",
            overflow: "auto",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: "10px",
              margin: "10px",
              width: "125px",

              backgroundColor:
                currentSelectedProfile != null &&
                currentSelectedProfile?.user?.id == ele?.user?.id
                  ? "#F0EFFE"
                  : "#fff",
              cursor: "pointer",
            }}
          >
            <div
              style={{
                overflow: "hidden",
                width: 80,
                height: 80,
                borderRadius: "100%",
              }}
            >
              <img
                src={
                  ele?.user?.patientProfile?.profileImage
                    ? `${ele?.user?.patientProfile?.profileImage}?token=${currentDocToken}`
                    : ProfileIcon
                }
                alt="Profile icon"
                style={{
                  objectFit: "cover",
                  width: "100%",
                  height: "100%",
                  objectPosition: "center",
                }}
              />
            </div>
            <div
              style={{
                marginTop: "10px",
                fontWeight: "600",
                fontSize: "12px",
                fontFamily: "Montserrat, sans serif",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <div>{ele?.user?.patientProfile?.firstName}</div>
              <div>{ele?.user?.patientProfile?.lastName}</div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const handleCheckboxChange = (e: any) => {
    setTermsAgree(e.target.checked);
  };

  return hashLoad ? (
    <Loader color="white" size="sm" mr="sm" />
  ) : (
    <>
      <LoginLayout scrollHidden>
        {openSignUp ? (
          <div></div>
        ) : // <SSOSignup
        //   intialValues={signupData}
        //   onSuccess={() => {}}
        //   relationships={[]}
        //   CTA="Sign up"
        // />
        showProfilePicker ? (
          <Box
            sx={(theme) => ({
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
            })}
          >
            <Box
              sx={(theme) => ({
                height: "100%",
                boxShadow: "none",
                padding: theme.spacing.lg,
                borderRadius: theme.radius.md,
                border: `1px solid ${theme.colors.gray[0]}`,
              })}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  height: "100%",
                  justifyContent: "space-around",
                }}
              >
                <div style={{ width: "100%", display: "flex" }}>
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      LogoutUser();
                      window.location.reload();
                      // navigate({ to: "/login" });
                    }}
                  >
                    <ArrowLeft />
                  </div>
                  <div>
                    {" "}
                    <Title
                      order={5}
                      mb="xl"
                      align="left"
                      style={{ marginLeft: "10px" }}
                    >
                      Choose a profile to continue
                    </Title>
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {multipleProfileList.map((ele: any) => {
                    return renderProfiles(ele);
                  })}
                </div>

                <div style={{ width: "200px" }}>
                  <Button
                    disabled={currentSelectedProfile == null}
                    radius={"sm"}
                    onClick={() => handleLoginChores(currentSelectedProfile)}
                    size={"md"}
                    fullWidth={true}
                    my={"lg"}
                    //id="login_button"
                  >
                    Continue
                    <ChevronRight />
                  </Button>
                </div>
              </div>
            </Box>
          </Box>
        ) : (
          <Box
            sx={(theme) => ({
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
            })}
          >
            <Box
              sx={(theme) => ({
                maxWidth: theme.breakpoints.xs,
                width: "100%",
                boxShadow: theme.shadows.md,
                padding: theme.spacing.lg,
                borderRadius: theme.radius.md,
                border: `1px solid ${theme.colors.gray[0]}`,
              })}
            >
              <Title order={2} mb="xl" align="center">
                Bookings made easy on the go
              </Title>
              <Group grow={true}>
                <Select
                  size="md"
                  radius="md"
                  label="Country code"
                  placeholder="Country code"
                  data={CountryCodes}
                  value={(countryCode as number).toString()}
                  onChange={(v) => setCountryCode(parseInt(v as string))}
                  sx={(theme) => ({
                    width: "30%",
                    flexGrow: 0,
                    [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
                      width: "100%",
                    },
                  })}
                  disabled={showOTP}
                />
                <TextInput
                  id="phone_no"
                  placeholder="Enter your phone number"
                  label="Phone no"
                  onCopy={(e) => e.preventDefault()}
                  onPaste={(e) => e.preventDefault()}
                  onCut={(e) => e.preventDefault()}
                  onChange={(e) => setPhone(parseInt(e.target.value))}
                  maxLength={10}
                  color="blue"
                  type="tel"
                  radius={"md"}
                  size={"md"}
                  style={{ maxWidth: "100%" }}
                  disabled={showOTP}
                />
              </Group>
              {phoneError && <Text color="red">{phoneError}</Text>}
              {showOTP && (
                <>
                  <TextInput
                    id="otp_login"
                    my="md"
                    placeholder="Enter the OTP received on your number"
                    onCopy={(e) => e.preventDefault()}
                    onPaste={(e) => e.preventDefault()}
                    onCut={(e) => e.preventDefault()}
                    label={
                      <Group>
                        <Text>OTP</Text>{" "}
                        <Text
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setShowOTP(false);
                            setTenantId(-1);
                            setOTP(undefined);
                            setOTPError("");
                            setOTPData(undefined);
                            window.clearInterval(inter);
                          }}
                          underline
                          color="zyla"
                        >
                          Change phone number?
                        </Text>
                      </Group>
                    }
                    maxLength={5}
                    onChange={(e) => setOTP(parseInt(e.target.value))}
                    type="tel"
                    radius={"md"}
                    size={"md"}
                  />
                  <Text
                    color="gray"
                    style={{
                      cursor: "pointer",
                      opacity: time === 0 ? "1" : "0.4",
                    }}
                    onClick={() => {
                      if (time > 1) {
                        return;
                      }
                      handleOTP();
                    }}
                  >
                    Resend OTP ({time})
                  </Text>
                </>
              )}
              {showOTP && OTPError && <Text color="red">{OTPError}</Text>}
              <Button
                radius={"sm"}
                onClick={() => handleLogin()}
                size={"md"}
                // disabled={loading}
                disabled={!termsAgree}
                fullWidth={true}
                my={"lg"}
                id="login_button"
              >
                {loading && <Loader color="white" size="sm" mr="sm" />}
                Login
                <ChevronRight />
              </Button>
              <Link to="/signup">
                <Text
                  id="sign_up"
                  color="zyla"
                  weight={"bold"}
                  align="center"
                  underline={true}
                >
                  Sign up using work email
                </Text>
              </Link>
            </Box>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "flex-start",
                marginTop: "16px",
              }}
            >
              <input
                type="checkbox"
                id="terms"
                onChange={handleCheckboxChange}
                style={{ marginTop: "4px" }}
              />
              <label
                htmlFor="terms"
                style={{ marginLeft: "8px", fontFamily: "Montserrat" }}
              >
                I provide my consent and agree to the{" "}
                <a
                  href="https://zyla.in/terms-content"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  'Terms and conditions'
                </a>
                <br />
                and{" "}
                <a
                  href="https://zyla.in/privacy-content"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  'Privacy policy'.
                </a>
              </label>
            </div>
          </Box>
        )}
        {showSnackbar && <MySnackbar msg={errorMsg} />}
      </LoginLayout>
    </>
  );
};

export default Login;

const MySnackbar = ({ msg }: { msg: String }) => {
  return (
    <div>
      <div
        style={{
          position: "fixed",
          bottom: "20px",
          left: "50%",
          transform: "translateX(-50%)",
          backgroundColor: "#333",
          color: "#fff",
          padding: "10px 20px",
          borderRadius: "4px",
          opacity: 1,
          transition: "opacity 0.3s ease",
        }}
      >
        {msg}
      </div>
    </div>
  );
};
