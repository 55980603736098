import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Title,
  Box,
  TextInput,
  Checkbox,
  Group,
  Button,
  RadioGroup,
  Radio,
  Textarea,
  Text,
  Select,
  Loader,
  Indicator,
  Input,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { DatePicker } from "@mantine/dates";
import { AxiosError } from "axios";
import { Relation } from "../../types/family";
import { api } from "../../utils/api";
import ApiConfig from "../../utils/apiconfig";
import { queryClient } from "../../App";
import { CT } from "../../constants";
import { CountryCodes } from "../../pages/login";
import dayjs from "dayjs";

import "./family.css";

export interface FamilyFormValues {
  fname: string;
  lname: string;
  relation: string;
  gender: string;
  dateOfBirth?: string;
  countryCode: number | undefined;
  phone: number | undefined;
  email?: string;
  OTP?: number;
}

interface FamilyFormProps {
  intialValues: FamilyFormValues;
  onSuccess(): void;
  CTA: string;
  relationships: Relation[];
  isUpdated: boolean;
  fid: string;
}

const FamilyForm = (props: FamilyFormProps) => {
  let namePattern = /^[A-Za-z0-9'-. _]+$/;
  let phoneNoPattern = /^\d{10}$/;
  let emailPattern =
    /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(\.[a-zA-Z]{2,})?$/;
  const form = useForm<FamilyFormValues>({
    initialValues: props.intialValues,
    validate: {
      fname: (value) =>
        namePattern.test(value) ? null : "Enter correct first name",
      lname: (value) =>
        namePattern.test(value) ? null : "Enter correct last name",
      phone: (value) =>
        phoneNoPattern.test(String(value))
          ? null
          : "Enter correct phone number",
      email: (value) =>
        emailPattern.test(String(value)) ? null : "Invalid email",
      dateOfBirth: (value) =>
        value == undefined || value === ""
          ? "Please select date of birth"
          : null,
      gender: (value) => (value === "" ? "Please select a gender" : null),
    },
  });

  const [OTP, setOTP] = useState<boolean>(false);
  const [OTPError, setOTPError] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const [minDateAllowed, setMinDateAllowed] = useState<Date>(new Date());
  const [maxDateAllowed, setMaxDateAllowed] = useState<Date>(new Date());
  const [currDefaultValue, setCurrDefaultValue] = useState<any>(null);
  const [dob, setDob] = useState<Date | null>(null);

  useEffect(() => {
    isDateDisabled(`${form.values.relation}`);
  }, [form.values]);

  const handleSubmit = async (values: FamilyFormValues) => {
    setLoading(true);
    setError("");
    if (values.relation == "6" || values.relation == "8") {
      try {
        let newRes = null;
        if (props.isUpdated) {
          const url = ApiConfig.family.update.replace("{0}", props.fid);
          newRes = await api.put(`${url}`, {
            firstName: values.fname,
            lastName: values.lname,
            dateOfBirth: dayjs(values.dateOfBirth).format("YYYY-MM-DD"),
            gender: parseInt(values.gender),
            email: values.email,
            countryCode: 91,
            relation: parseInt(values.relation),
          });
        } else {
          newRes = await api.post(ApiConfig.family.createMemberMinor, {
            firstName: values.fname,
            lastName: values.lname,
            dateOfBirth: dayjs(values.dateOfBirth).format("YYYY-MM-DD"),
            gender: parseInt(values.gender),
            email: values.email,
            countryCode: 91,
            relation: parseInt(values.relation),
          });
        }
        // queryClient.setQueryData(["family-members"], (old: any) => [
        //   ...old,
        //   newRes.data,
        // ]);
        form.reset();
        props.onSuccess();
      } catch (error) {
        if (error instanceof AxiosError) {
          setError(error?.response?.data?.message);
        }
      }
      setLoading(false);
      return;
    }

    if (props.isUpdated) {
      try {
        const url = ApiConfig.family.update.replace("{0}", props.fid);

        let newRes = await api.put(`${url}`, {
          firstName: values.fname,
          lastName: values.lname,
          dateOfBirth: dayjs(values.dateOfBirth).format("YYYY-MM-DD"),
          gender: parseInt(values.gender),
          email: values.email,
        });

        // queryClient.setQueryData(["family-members"], (old: any) => [
        //   ...old,
        //   newRes.data,
        // ]);
        form.reset();
        props.onSuccess();
      } catch (error) {
        if (error instanceof AxiosError) {
          setError(error?.response?.data?.message);
        }
      }
      setLoading(false);
      return;
    }

    if (OTP === false) {
      try {
        // send OTP if not there
        const res = await api.post(ApiConfig.family.getOTP, {
          phoneNo: parseInt(values.phone as unknown as string),
          countryCode: parseInt(values.countryCode as unknown as string),
          memberRelation: parseInt(values.relation),
        });
        setOTP(true);
      } catch (error) {
        if (error instanceof AxiosError) {
          setError(error?.response?.data?.message);
        }
      }
    } else {
      // verify OTP and add member
      try {
        const res = await api.post<{ id: number }>(ApiConfig.family.verifyOTP, {
          otp: parseInt(values.OTP as unknown as string),
          phoneNo: parseInt(values.phone as unknown as string),
          countryCode: parseInt(values.countryCode as unknown as string),
          memberRelation: parseInt(values.relation),
        });
        // create member if otp right

        let newRes = await api.post(ApiConfig.family.createMember, {
          requestId: res.data?.id,
          firstName: values.fname,
          lastName: values.lname,
          dateOfBirth: dayjs(values.dateOfBirth).format("YYYY-MM-DD"),
          gender: parseInt(values.gender),
          email: values.email,
          relation: parseInt(values.relation),
        });

        // queryClient.setQueryData(["family-members"], (old: any) => [
        //   ...old,
        //   newRes.data,
        // ]);
        form.reset();
        props.onSuccess();
      } catch (error) {
        if (error instanceof AxiosError) {
          setError(error?.response?.data?.message);
        }
      }
    }
    setLoading(false);
  };

  const isDateDisabled = (value: string | null) => {
    const eighteenYearsAgo = new Date();
    eighteenYearsAgo.setFullYear(eighteenYearsAgo.getFullYear() - 18);
    const hundredYearsAgo = new Date();
    hundredYearsAgo.setFullYear(hundredYearsAgo.getFullYear() - 100);
    if (value === "6" || value === "8") {
      setMinDateAllowed(eighteenYearsAgo);
      setMaxDateAllowed(new Date());
      setCurrDefaultValue(new Date());
      return;
    } else if (value !== "10" && value !== "6" && value !== "8") {
      setMaxDateAllowed(eighteenYearsAgo);
      setMinDateAllowed(hundredYearsAgo);
      setCurrDefaultValue(eighteenYearsAgo);
      return;
    } else {
      setMinDateAllowed(hundredYearsAgo);
      setMaxDateAllowed(new Date());
    }
  };

  const getDisableStatus = (date: Date) => {
    if (
      dayjs(date).isAfter(maxDateAllowed) ||
      dayjs(date).isBefore(minDateAllowed)
    ) {
      return true;
    }
    return false;
  };
  return (
    <Box mx="auto">
      <form
        onSubmit={(e) => {
          e.preventDefault();
          if (form.values.relation == "6" || form.values.relation == "8") {
            form.setFieldValue("phone", 3333333333);
          }
          const { hasErrors } = form.validate();
          if (!hasErrors) {
            handleSubmit(form.values);
          }
        }}
      >
        <TextInput
          id="first_name_family"
          required
          size="md"
          radius={"md"}
          label="First name"
          placeholder="First name of the member"
          {...form.getInputProps("fname")}
          py={8}
          disabled={loading}
        />
        <TextInput
          id="last_name_family"
          required
          size="md"
          radius={"md"}
          label="Last name"
          placeholder="Last name of the member"
          {...form.getInputProps("lname")}
          py={8}
          disabled={loading}
        />
        {/* <RadioGroup
          py={8}
          label="Relationship"
          size="md"
          required
          orientation="vertical"
          {...form.getInputProps("relation")}
        >
          {props.relationships.map((relation) => (
            <Radio
              value={relation.code.toString()}
              label={relation.value}
              key={relation.code}
            ></Radio>
          ))}
        </RadioGroup> */}
        {!props.isUpdated ? (
          <Select
            id="relationship_family"
            required
            label="Relationship"
            placeholder="Relationship"
            size="md"
            radius={"md"}
            disabled={loading || props.isUpdated}
            {...form.getInputProps("relation")}
            onChange={(value) => {
              form.setFieldValue("relation", value as string);
              form.setFieldValue("dateOfBirth", "");

              setDob(null);

              if (value == "6" || value == "8") {
                form.setFieldValue("phone", 3333333333);
              }

              value === "1" ||
              value === "2" || //to check gender radio as per relation
              value === "8" ||
              value === "9"
                ? form.setFieldValue("gender", "2" as string)
                : value === "3" ||
                  value === "4" ||
                  value === "6" ||
                  value === "7"
                ? form.setFieldValue("gender", "1" as string)
                : "";

              isDateDisabled(value);
            }}
            data={props.relationships.map((r) => {
              return { value: r.code.toString(), label: r.value };
            })}
          />
        ) : (
          <div>
            <label
              htmlFor="relationship_family"
              style={{
                fontFamily: "Montserrat",
                marginBottom: "8px",
                display: "block",
              }}
            >
              Relationship <a style={{ color: "red" }}>*</a>
            </label>
            <Input
              id="relationship_family"
              value={
                props.relationships.find(
                  (r) => r.code === form.values.relation // Find matching code
                )?.value || "" // Display the corresponding label
              }
              size="md"
              radius={"md"}
              disabled={true}
            />
          </div>
        )}

        <div>
          <RadioGroup
            label="Gender"
            value={String(form.values.gender)}
            onChange={(value) => form.setFieldValue("gender", value)}
            size="md"
            spacing="md"
            required
          >
            <Radio
              value="1"
              label="Female"
              disabled={
                form.values.relation != "5" && form.values.relation != "10"
              }
            />
            <Radio
              value="2"
              label="Male"
              disabled={
                form.values.relation != "5" && form.values.relation != "10"
              }
            />
          </RadioGroup>
        </div>

        <DatePicker
          id="dob_family"
          placeholder="Enter your date of birth"
          className="family-date-picker"
          rightSectionProps={{ id: "dob_clear" }}
          error={form.errors["dateOfBirth"]}
          value={
            form.getInputProps("dateOfBirth").value
              ? new Date(form.getInputProps("dateOfBirth").value)
              : null
          }
          dayClassName={() => {
            return "family-mantine-dob-day";
          }}
          onChange={(e) => {
            form.setFieldValue("dateOfBirth", e);
            setDob(e);
          }}
          renderDay={(date) => {
            const day = date.getDate();
            const weekDay = date.getDay();
            return (
              <button
                className={`family-mantine-day-renderer-class ${
                  weekDay === 6 || weekDay === 0
                    ? "family-mantine-day-highlight"
                    : ""
                }`}
                disabled={Boolean(getDisableStatus(date))}
              >
                <div>{day}</div>
              </button>
            );
          }}
          label="Date of birth"
          initialMonth={currDefaultValue}
          size="md"
          required
          disabled={loading || !form.getInputProps("relation").value}
          defaultValue={
            currDefaultValue || form.getInputProps("dateOfBirth").value
          }
          radius="md"
          py={8}
        />

        <TextInput
          id="email_family"
          size="md"
          radius={"md"}
          label="Email ID"
          py={8}
          type="email"
          disabled={loading}
          required
          placeholder="Email"
          {...form.getInputProps("email")}
        />
        <Group grow={true} mb={20}>
          <Select
            id="country_code_family"
            size="md"
            radius="md"
            label="Country code"
            defaultValue={"91"}
            placeholder="Country code"
            disabled={
              loading ||
              form.values.relation == "6" ||
              form.values.relation == "8" ||
              props.isUpdated
            }
            data={CountryCodes}
            // value={form.values.countryCode?.toString()}
            // onChange={(v) => setCountryCode(parseInt(v as string))}
            {...form.getInputProps("countryCode")}
            style={{ width: "30%", flexGrow: 0 }}
          />
          <TextInput
            id="phone_no_family"
            required
            size="md"
            type="tel"
            radius={"md"}
            label="Phone number"
            onCopy={(e) => e.preventDefault()}
            onPaste={(e) => e.preventDefault()}
            disabled={
              loading ||
              form.values.relation == "6" ||
              form.values.relation == "8" ||
              props.isUpdated
            }
            maxLength={10}
            style={{ minWidth: "66%" }}
            placeholder="Phone number"
            {...form.getInputProps("phone")}
          />
        </Group>

        {OTP && (
          <>
            <TextInput
              id="otp_family"
              required
              size="md"
              radius={"md"}
              label="OTP"
              disabled={loading}
              maxLength={5}
              py={8}
              placeholder="Enter your OTP"
              {...form.getInputProps("OTP")}
            />
            <Text color="red">{OTPError}</Text>
          </>
        )}
        {error != "" && <Text color="red">{error}</Text>}

        <Group>
          <Button
            size="md"
            my="8"
            type="submit"
            disabled={loading}
            id="add_family"
          >
            {loading && <Loader color={"white"} size="sm" mr="md" />}
            {props.CTA}
          </Button>
        </Group>
      </form>
    </Box>
  );
};

export default FamilyForm;
