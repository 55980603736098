import React, { useState, useEffect } from "react";
import {
  AppShell,
  Navbar,
  ScrollArea,
  UnstyledButton,
  Box,
  Group,
  ThemeIcon,
  Text,
  MantineColor,
  Image,
  Container,
  Tooltip,
  Header,
  Burger,
  MediaQuery,
  useMantineTheme,
  Button,
} from "@mantine/core";
import {
  Home,
  User,
  Users,
  Calendar,
  Info,
  ChevronRight,
  MapPin,
} from "react-feather";
import Logo from "../assets/logo.svg";
import { Link, useNavigate } from "@tanstack/react-location";
import { GetProfile, LogoutUser } from "../services/auth";
import { getDocumentToken } from "../services/document";

interface NavItemProps {
  icon: React.ReactNode;
  label: string;
  onClick?: () => void;
  theme: MantineColor;
  selected?: boolean;
  link?: string;
  disabled?: boolean;
}

const NavItem = (props: NavItemProps) => {
  const handleClick = () => {
    if (props.label === "Home") {
      window.location.href = "/";
    }
  };
  return (
    <Link disabled={props.disabled} to={props.link}>
      {({ isActive }) => {
        return (
          <Tooltip
            disabled={!props.disabled}
            position="right"
            withArrow
            label="Complete your profile to continue."
            style={{ display: "block" }}
          >
            <UnstyledButton
              id={`${props.label}_label`}
              p={16}
              my={4}
              onClick={handleClick}
              sx={(theme) => ({
                borderRadius: theme.radius.md,
                width: "100%",
                opacity: props.disabled ? 0.5 : 1,
                cursor: props.disabled ? "not-allowed" : "pointer",
                backgroundColor: isActive
                  ? theme.colors.gray[1]
                  : theme.colors.white,
                "&:hover": {
                  backgroundColor: theme.colors.gray[1],
                },
              })}
            >
              <Box>
                <Group>
                  <ThemeIcon variant="light" color={props.theme}>
                    {props.icon}
                  </ThemeIcon>
                  <Text size="sm">{props.label}</Text>
                </Group>
              </Box>
            </UnstyledButton>
          </Tooltip>
        );
      }}
    </Link>
  );
};

const NavItems: NavItemProps[] = [
  {
    icon: <Home size={18} />,
    label: "Home",
    theme: "blue",
    link: "/",
  },
  {
    icon: <User size={18} />,
    label: "Profile",
    theme: "green",
    link: "/profile",
  },
  {
    icon: <MapPin size={18} />,
    label: "Addresses",
    theme: "orange",
    link: "/address",
  },
  {
    icon: <Calendar size={18} />,
    label: "Appointments",
    theme: "violet",
    link: "/appointments",
  },
  {
    icon: <Users size={18} />,
    label: "Family",
    theme: "grape",
    link: "/family",
  },
  {
    icon: <Info size={18} />,
    label: "Help",
    theme: "cyan",
    link: "/help",
  },
];

const HomeLayout = ({ children }: { children: React.ReactNode }) => {
  const [opened, setOpened] = useState<boolean>(false);
  const theme = useMantineTheme();
  const navigate = useNavigate();
  const user = GetProfile();
  const handleLogout = () => {
    LogoutUser();
    navigate({ to: "/login" });
  };

  function capitalizeFirstLetter(inputString: string) {
    if (typeof inputString !== "string" || inputString.length === 0) {
      return "";
    }
    const capitalizedString =
      inputString.charAt(0).toUpperCase() + inputString.slice(1).toLowerCase();

    return capitalizedString;
  }

  useEffect(() => {
    if (opened) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "scroll";
    }
  }, [opened]);

  const token = getDocumentToken();

  return (
    <AppShell
      padding={0}
      navbarOffsetBreakpoint="sm"
      navbar={
        <MediaQuery
          smallerThan="sm"
          styles={{ width: "100vw", position: "fixed", top: 0, paddingTop: 70 }}
        >
          <Navbar
            hiddenBreakpoint="sm"
            hidden={!opened}
            width={{ base: 300 }}
            height={opened ? "100%" : "100vh"}
            p={12}
          >
            <MediaQuery smallerThan="sm" styles={{ display: "none" }}>
              <Navbar.Section mt="xs" px={12 + 8}>
                {" "}
                <img src={Logo} alt="logo" height={48} width={98} />
              </Navbar.Section>
            </MediaQuery>

            <Navbar.Section
              grow
              component={ScrollArea}
              mx="-xs"
              px="xs"
              style={{ marginTop: "40px" }}
            >
              {NavItems.map((item, index) => (
                <div id={`nav-${index}`}>
                  <NavItem key={item.label} {...item} />
                </div>
              ))}
            </Navbar.Section>

            <Navbar.Section>
              <Box
                py={20}
                sx={(theme) => ({
                  borderTop: `1px solid ${theme.colors.gray[1]}`,
                })}
              >
                <Group>
                  <img
                    style={{ borderRadius: "50%", overflow: "hidden" }}
                    src={
                      user?.patientProfile?.profileImage === ""
                        ? "https://avatars.dicebear.com/api/pixel-art/willll.svg"
                        : user?.patientProfile?.profileImage +
                          "?token=" +
                          encodeURIComponent(token)
                    }
                    alt="avatar"
                    height={36}
                    width={36}
                  />
                  <Box>
                    <Text size="sm" weight={"bold"}>
                      {capitalizeFirstLetter(user?.patientProfile?.firstName) +
                        " " +
                        capitalizeFirstLetter(user?.patientProfile?.lastName)}
                    </Text>
                    <Text
                      size="xs"
                      color={"gray"}
                      onCopy={(e) => e.preventDefault()}
                      onCut={(e) => e.preventDefault()}
                    >
                      {user?.patientProfile?.email}
                    </Text>
                    <UnstyledButton onClick={() => handleLogout()} id="logout">
                      <Text size="xs" color="dark">
                        Logout
                      </Text>
                    </UnstyledButton>
                  </Box>
                  <>
                    <ChevronRight
                      onClick={() => handleLogout()}
                      style={{ cursor: "pointer" }}
                    />
                  </>
                </Group>
              </Box>
            </Navbar.Section>
          </Navbar>
        </MediaQuery>
      }
      header={
        <MediaQuery largerThan="sm" styles={{ display: "none" }}>
          <Header
            height={70}
            p="md"
            style={{ display: "fixed", zIndex: 200 }}
            fixed={opened}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                height: "100%",
              }}
            >
              <img src={Logo} alt="logo" height={36} width={72} />
              <Burger
                opened={opened}
                onClick={() => setOpened((o) => !o)}
                size="sm"
                color={theme.colors.gray[6]}
                mr="xl"
              />
            </div>
          </Header>
        </MediaQuery>
      }
    >
      <ScrollArea style={{ height: "100vh" }}>
        <Container size="lg" pb={120}>
          {children}
        </Container>
      </ScrollArea>
    </AppShell>
  );
};

export default HomeLayout;
