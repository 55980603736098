import { useEffect, useState } from "react";
import {
  Box,
  Text,
  Title,
  Grid,
  Image,
  Button,
  Group,
  Modal,
  Card,
  Notification,
} from "@mantine/core";
import { Calendar, Check, ChevronRight, Clock, User, X } from "react-feather";
import { AxiosError } from "axios";
import HomeLayout from "../layouts/HomeLayout";
import OnboardingForm, { OnboardingFormValues } from "../components/onboarding";
import { GetProfile } from "../services/auth";
import { api } from "../utils/api";
import ApiConfig from "../utils/apiconfig";
import { setItem } from "../services/storage";
import { CT, USER_PROFILE } from "../constants";
import dayjs from "dayjs";
import { AddressFormValues } from "../components/address";
import { useSearch } from "@tanstack/react-location";

const Index = () => {
  const profile = GetProfile();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [notification, setNotification] = useState<boolean>(false);
  let { email: showEmailPrompt } = useSearch();
  const [showUpdateEmail, setShowUpdateEmail] = useState<boolean>(false);

  useEffect(() => {
    var buttonElement = document.querySelector(
      ".mantine-DatePicker-calendarHeaderLevel"
    );

    // Check if the button element exists
    if (buttonElement) {
      // Dynamically assign an ID to the button element
      buttonElement.id = "profile_dob";

      // You can also do other operations with the buttonElement here if needed
    }
    if (showEmailPrompt === true) {
      setShowUpdateEmail(true);
    }
  }, []);

  const handleEdit = async (
    values: OnboardingFormValues,
    address: AddressFormValues | undefined
  ) => {
    setLoading(true);
    const url = ApiConfig.patient.update.replace(
      "{0}",
      profile?.patientProfile?.id
    );
    try {
      let res = await api.put(url, {
        firstName: values.firstName,
        lastName: values.lastName,
        gender: values.gender,
        dateOfBirth: dayjs(values.dateOfBirth).unix() * 1000,
        email: values.email,
      });
      // update in local cache
      let prev = GetProfile();
      prev.patientProfile = res.data;
      setItem(USER_PROFILE, prev);
      setNotification(true);
      setShowUpdateEmail(false);
    } catch (error) {
      if (error instanceof AxiosError) {
        setError(error?.response?.data.message);
        console.error(error);
      }
      setShowUpdateEmail(false);
    }
    setLoading(false);
  };

  return (
    <HomeLayout>
      <Box py={40}>
        <Title mb={8}>Profile</Title>
        <Text size="lg" weight={"bold"} mt={20} pb={10}>
          Basic profile
        </Text>

        <Grid>
          <Grid.Col md={6} span={12}>
            <>
              <Notification hidden={!showUpdateEmail as boolean}>
                Please update your email to avail the service.
              </Notification>
              <Notification
                hidden={!notification}
                color={"green"}
                icon={<Check />}
                title="Profile updated successfully."
                onClose={() => setNotification(false)}
              />
              <OnboardingForm
                isProfileForm={true}
                loading={loading}
                error={error}
                address={
                  profile?.patientProfile?.address !== null
                    ? profile?.patientProfile?.address
                    : undefined
                }
                intialValues={{
                  firstName: profile?.patientProfile?.firstName,
                  lastName: profile?.patientProfile?.lastName,
                  gender: profile?.patientProfile?.gender.toString(),
                  phone: profile?.patientProfile?.phoneno,
                  dateOfBirth:
                    profile?.patientProfile?.dateOfBirth !== null
                      ? dayjs(profile?.patientProfile?.dateOfBirth).toDate()
                      : "",
                  email: profile?.patientProfile?.email,
                }}
                onSubmit={(values, address) => handleEdit(values, address)}
              />
            </>
          </Grid.Col>
        </Grid>
      </Box>{" "}
    </HomeLayout>
  );
};

export default Index;
