import { useEffect, useState } from "react";
import dayjs from "dayjs";
import { Title, Text, Grid } from "@mantine/core";
import LoginLayout from "../layouts/LoginLayout";
import OnboardingForm, { Address } from "../components/onboarding";
import type { OnboardingFormValues } from "../components/onboarding";
import { Link, useNavigate } from "@tanstack/react-location";
import {
  GetPhoneOTP,
  VerifyPhoneOTP,
  RegisterUser,
  RegisterSource,
  SetPatientID,
  RefreshToken,
} from "../services/auth";
import { setItem, getItem } from "../services/storage";
import { AxiosError } from "axios";
import { Register } from "../types/auth";
import { CT } from "../constants";
import { api } from "../utils/api";

const Onboarding = () => {
  const [otpData, setOTP] = useState<any>({});
  const [showOTP, setShowOTP] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [resend, setResend] = useState<boolean>(false);

  const navigate = useNavigate();

  const [error, setError] = useState<string>("");
  const [errorCode, setErrorCode] = useState<any>("");
  const onSubmit = async (
    values: OnboardingFormValues,
    address: Address | undefined
  ) => {
    setError("");
    setLoading(true);
    // handle generate otp
    if (values.otp === undefined) {
      try {
        let res = await GetPhoneOTP({
          countryCode: parseInt(values.countryCode),
          phone: values.phone,
        });
        setOTP(res.data);
        setShowOTP(true);
      } catch (error) {
        if (error instanceof AxiosError) {
          setError(error?.response?.data.message);
          console.error(error);
        }
      }
    } else {
      try {
        let OTPData = { ...otpData.user, otp: values.otp?.toString() };
        let res = await VerifyPhoneOTP({ user: OTPData });
        setItem("ACCESS_TOKEN", res.data?.token);
        setItem("USER_PROFILE", res.data?.user);
        // if patient profile stop the onboarding
        if (res.data?.user?.patientProfile !== null) {
          setError(
            "This number is already registered, please try again with a new number."
          );
          setLoading(false);
          setShowOTP(false);
          setErrorCode("exists");
          return false;
        }
        // register
        try {
          let prevUser = getItem("INITIAL_PROFILE");
          let email = prevUser?.email;
          // send source call
          let sourceRes = await RegisterSource({
            countryCode: parseInt((values.countryCode as number).toString()),
            //@ts-ignore
            phoneNo: parseInt(values.phone as number),
            source: "navigo",
          });
          // gender - male: 2 female: 1
          let request: Register = {
            user: {
              patientProfile: {
                firstName: values.firstName,
                lastName: values.lastName,
                email: email,
                gender: values.gender,

                dateOfBirth: dayjs(values.dateOfBirth).format("YYYY-MM-DD"),
              },
              phoneNo: values.phone,
              countrycode: parseInt(values.countryCode as number),
            },
          };
          if (address !== undefined) {
            request.user.patientProfile.address = address;
          }
          let res = await RegisterUser(request);
          // @ts-ignore
          const pp = res.data?.user?.patientProfile;

          await SetPatientID({
            email: email,
            patientId: res.data?.user?.patientProfile?.id,
          });
          setItem("USER_PROFILE", res.data?.user);
          await RefreshToken();
          navigate({ to: "/", replace: true });
        } catch (error) {
          if (error instanceof AxiosError) {
            console.error(error);
          }
        }
      } catch (error) {
        if (error instanceof AxiosError) {
          setError(error?.response?.data.message);
          if (error?.response?.status === 410) {
            // show resend
            setResend(true);
          }
          console.error(error);
        }
      }
    }
    setLoading(false);
  };
  return (
    <LoginLayout>
      <Grid justify={"center"} align={"center"}>
        <Grid.Col xs={6} my={40}>
          <Title order={1}>Almost there</Title>
          <Text my={8}>
            Please enter with following details to avail the services.
          </Text>
          <OnboardingForm
            loading={loading}
            showOTP={showOTP}
            showResend={resend}
            handleChangeNumber={() => {
              setShowOTP(false);
              setError("");
              setErrorCode("");
            }}
            intialValues={{
              firstName: "",
              gender: "0",
              lastName: "",
              phone: undefined,
              otp: undefined,
              countryCode: 91,
            }}
            error={error}
            onSubmit={onSubmit}
            errorCode={errorCode}
            setErrorCode={setErrorCode}
          />
        </Grid.Col>
      </Grid>
    </LoginLayout>
  );
};

export default Onboarding;
