import { useEffect, useState } from "react";
import {
  Box,
  Text,
  Title,
  Grid,
  Image,
  Button,
  Group,
  Modal,
  Loader,
  Notification,
} from "@mantine/core";
import { Edit2, Calendar, User, Check, Phone } from "react-feather";
import HomeLayout from "../layouts/HomeLayout";
import Card from "../components/card";
import FamilyForm from "../components/family";
import empty from "../assets/family-empty.svg";
import { useLocation, useNavigate } from "@tanstack/react-location";
import { GetProfile } from "../services/auth";
import dayjs from "dayjs";
import { getFamilyMembers, getRelationList } from "../services/family";
import { Relation } from "../types/family";
import { queryClient } from "../App";
import { CT } from "../constants";

const FamilyEmpty = ({ onClick }: { onClick: any }) => (
  <Box
    sx={{
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
      width: "100%",
      padding: "20px 0px",
    }}
  >
    <img
      src={empty}
      alt="You can add your family members here to avail available services for them."
    />
    <Text my={16} color={"dark"}>
      You can add your family members here to avail available services for them.
    </Text>
    <Button
      id="add_family_1"
      size="md"
      radius={"md"}
      onClick={() => onClick(true)}
    >
      + Add a new family member
    </Button>
  </Box>
);

const Index = () => {
  const [form, setForm] = useState(false);
  const [formEdit, setFormEdit] = useState(false);
  const [fid, setFid] = useState();
  const [isUpdated, setIsUpdated] = useState(false);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(true);
  const familyMembers: any = [];
  const navigate = useNavigate();

  const profile = GetProfile();

  const { data: relationMappingData, isLoading } = getRelationList();
  const { data } = getFamilyMembers({ enabled: !isLoading });

  useEffect(() => {
    var buttonElement = document.querySelector(
      ".mantine-DatePicker-calendarHeaderLevel"
    );

    // Check if the button element exists
    if (buttonElement) {
      // Dynamically assign an ID to the button element
      buttonElement.id = "family_dob";

      // You can also do other operations with the buttonElement here if needed
    }
  }, []);

  return (
    <HomeLayout>
      <Box py={40}>
        <Title mb={8}>Family</Title>
        {!isUpdated && (
          <Notification
            hidden={!success}
            radius="md"
            mt="xl"
            color={"green"}
            icon={<Check />}
            title="Family member added."
            onClose={() => setSuccess(false)}
          />
        )}
        {isUpdated && (
          <Notification
            hidden={!success}
            radius="md"
            mt="xl"
            color={"green"}
            icon={<Check />}
            title="Family member updated."
            onClose={() => setSuccess(false)}
          />
        )}

        <Text size="lg" weight={"bold"} mt={44} pb={20}>
          Me
        </Text>
        <Grid>
          <Grid.Col xs={4} span={12}>
            <Card
              title={
                profile?.patientProfile?.firstName.charAt(0).toUpperCase() +
                profile?.patientProfile?.firstName.slice(1).toLowerCase() +
                " " +
                profile?.patientProfile?.lastName.charAt(0).toUpperCase() +
                profile?.patientProfile?.lastName.slice(1).toLowerCase()
              }
              meta="self"
              actions={[
                {
                  icon: <Edit2 size={14} style={{ marginRight: 4 }} />,
                  title: "Edit",
                  type: "indigo",
                  action: () => navigate({ to: "/profile" }),
                },
              ]}
              info={[
                {
                  icon: <Calendar size={14} />,
                  title:
                    profile?.patientProfile?.dateOfBirth !== null
                      ? dayjs(profile?.patientProfile?.dateOfBirth).format(
                          "DD MMM, YYYY"
                        )
                      : "",
                },
                {
                  icon: <User size={14} />,
                  title:
                    profile?.patientProfile?.gender == 1 ? "Female" : "Male",
                },
              ]}
            />
          </Grid.Col>
        </Grid>
        <Modal
          title={<Title order={4}>Add a family member</Title>}
          onClose={() => setForm(false)}
          opened={form}
          closeOnClickOutside={false}
        >
          {!isLoading && (
            <FamilyForm
              relationships={relationMappingData as Relation[]}
              CTA="+ Add"
              intialValues={{
                gender: "",
                fname: "",
                lname: "",
                phone: undefined,
                countryCode: 91,
                relation: "",
                dateOfBirth: "",
                email: "",
              }}
              onSuccess={() => {
                // invalidate and show notification
                queryClient.invalidateQueries();
                queryClient.invalidateQueries(["family-members"]);

                setForm(false);
                setSuccess(true);
              }}
            />
          )}
        </Modal>

        <Modal
          title={<Title order={4}>Edit family member details</Title>}
          onClose={() => setFormEdit(false)}
          opened={formEdit}
          closeOnClickOutside={false}
        >
          {!isLoading && (
            <FamilyForm
              relationships={relationMappingData as Relation[]}
              CTA="Update profile >"
              intialValues={(() => {
                const member = data?.find(
                  (member) => member.memberPatientId === fid
                );
                return member
                  ? {
                      gender: member.gender,
                      fname: member.firstName || "",
                      lname: member.lastName || "",
                      phone: member.phoneNo.toString() || undefined,
                      countryCode: member.countryCode || 91,
                      relation:
                        relationMappingData?.find(
                          (r) => r.code === member.relation
                        )?.code || "",
                      dateOfBirth: member.dateOfBirth
                        ? new Date(member.dateOfBirth)
                        : null,
                      email: member.email || "",
                    }
                  : {
                      gender: "",
                      fname: "",
                      lname: "",
                      phone: undefined,
                      countryCode: 91,
                      relation: "",
                      dateOfBirth: "",
                      email: "",
                    };
              })()}
              onSuccess={() => {
                // Invalidate queries and show notification
                queryClient.invalidateQueries();
                queryClient.invalidateQueries(["family-members"]);

                setFormEdit(false);
                setSuccess(true);
              }}
              isUpdated={isUpdated}
              fid={fid}
            />
          )}
        </Modal>

        <Group position="apart">
          <Text size="lg" weight={"bold"} mt={44} pb={20}>
            Family
          </Text>

          <Button
            id="add_family_2"
            size="xs"
            radius={"md"}
            onClick={() => setForm(true)}
          >
            + Add a new family member
          </Button>
        </Group>

        {isLoading ? (
          <Loader />
        ) : (
          <Grid>
            {data?.length === 0 && <FamilyEmpty onClick={setForm} />}
            {data?.map((f, index) => (
              <Grid.Col xs={4} span={12}>
                <div id={String(index)}>
                  <Card
                    title={
                      f.firstName.charAt(0).toUpperCase() +
                      f.firstName.slice(1).toLowerCase() +
                      " " +
                      f.lastName.charAt(0).toUpperCase() +
                      f.lastName.slice(1).toLowerCase()
                    }
                    meta={
                      relationMappingData?.filter(
                        (r) => r.code === f.relation
                      )[0].value
                    }
                    actions={[
                      {
                        icon: <Edit2 size={14} style={{ marginRight: 4 }} />,
                        title: "Edit",
                        type: "indigo",
                        action: () => {
                          setFormEdit(true);
                          setFid(f.memberPatientId);
                          setIsUpdated(true);
                        },
                      },
                    ]}
                    info={[
                      {
                        icon: <Phone size={14} />,
                        title: `(${f.countryCode})${f.phoneNo.toString()}`,
                      },
                      {
                        icon: <User size={14} />,
                        title: f.gender == 1 ? "Female" : "Male",
                      },
                    ]}
                  />
                </div>
              </Grid.Col>
            ))}
          </Grid>
        )}
      </Box>{" "}
    </HomeLayout>
  );
};

export default Index;
